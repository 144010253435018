import http from '@/utils/http'
/**
 * 构建组织机构树
 * @param {*} list
 */
export const buildOrgTree = (list = []) => {
  if (!Array.isArray(list) || list.length === 0) return
  const res = []
  const map = {}
  const copy = list.map(it => {
    const { keyId } = it
    delete it.children
    map[keyId] = it
    return it
  })

  copy.forEach(element => {
    const { parentId } = element
    const parent = map[parentId]
    if (parent) {
      (parent.children || (parent.children = [])).push(element)
    } else {
      res.push(element)
    }
  })

  return res
}

/**
 * 校验是否重复的名称和电话
 * @param {*} phone
 * @returns
 */
export const checkSameInput = (phone) => {
  return http({
    hideLoading: true,
    url: `/api/authcenter/base/user/exist?target=${phone}`
  })
}
