<template>
  <div class="tree_box">
    <div class="input_box">
      <exclude-special-input
        v-model="searchValue"
        :showAppend="true"
        @search="searchTree"
        placeholder="请输入搜索内容"
      ></exclude-special-input>
      <i class="icon el-icon-refresh" @click="loadData(true)"></i>
      <i
        class="icon iconfont iconzhankai"
        @click="handleClose(true)"
        :class="{ down: expanded }"
      ></i>
    </div>
    <!-- <div class="tree_content"> -->
    <el-tree
      :data="data"
      node-key="keyId"
      :filter-node-method="filterNode"
      class="org_tree tree_content"
      ref="org_tree"
      @node-expand="nodeExpand"
      :default-expand-all="true"
      @node-collapse="nodeCollapse"
      :default-expanded-keys="defaultExpandedKeys"
      :expand-on-click-node="false"
      :props="defaultProps"
      @node-click="handleNodeClick"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <tool-tip-span
          class="node_label"
          :label="node.label"
          :content="node.label"
          :class="{ check: node.label === currentName }"
        />
        <span class="actions">
          <tip-btn
            tips="新增"
            icon="el-icon-plus"
            :click="() => handleEditor(data, 'C', node)"
          ></tip-btn>
          <tip-btn
            tips="编辑"
            icon="iconfont iconbianji3"
            :click="() => handleEditor(data, 'E', node)"
          ></tip-btn>
          <tip-btn
            tips="删除"
            icon="iconfont iconshanchu1"
            v-if="!data.deptList"
            :click="() => handleDelItem(data, node)"
          ></tip-btn>
        </span>
      </span>
    </el-tree>
    <!-- </div> -->

    <org-edit ref="orgEdit" @refresh="loadData(true)"></org-edit>
  </div>
</template>

<script>
import { orgApi } from '@/api/orgApi'
import { buildOrgTree } from '../helper'
import TipBtn from './tip-btn.vue'
import ExcludeSpecialInput from './exclude-special-input.vue'
import ToolTipSpan from './tool-tip-span.vue'
// import { groupManager, PLATFORM_MANAGER, sysManager } from '@/config'
export default {
  components: {
    ExcludeSpecialInput,
    TipBtn,
    orgEdit: () => import('./org-edit.vue'),
    ToolTipSpan
  },
  data () {
    return {
      searchValue: '',
      currentDefaultKeys: [],
      defaultExpandedKeys: [],
      editorNode: null,
      baseInitData: [],
      editorType: '',
      expanded: false,
      data: [],
      defaultProps: {
        children: 'deptList',
        label: 'orgName'
      },
      currentItem: Object.create(null)
    }
  },
  mounted () {
    this.loadData()
  },
  computed: {
    currentName () {
      return this.currentItem.orgName || ''
    },
    roleCode () {
      return this.$store.getters.roleInfo.roleCode || ''
    }
  },
  watch: {
    currentName () {
      this.$emit('companyChange', this.currentItem)
    },
    searchValue (val) {
      this.$refs.org_tree.filter(val)
    }
  },
  methods: {
    searchTree () {
      this.$refs.org_tree.filter(this.searchValue)
    },
    // 收起展示项
    handleClose () {
      const expanded = this.expanded
      const nodeDatas = this.$refs.org_tree.store.nodesMap
      // 循环所有节点，将需要收起的节点数据的 expanded 属性设置为false
      for (const key in nodeDatas) {
        nodeDatas[key].expanded = expanded
        this.expanded = !expanded
      }
    },
    showAddbtn (data) {
      // 新增按钮
      // if (
      //   // 系统管理员
      //   (this.roleCode === sysManager && data.companyType === 0) ||
      //   // 集团管理员
      //   (this.roleCode === groupManager && data.companyType === 4) ||
      //   // 核心企业
      //   (this.roleCode === PLATFORM_MANAGER && data.companyType === 1)
      // ) {
      //   return true
      // }
      // return false
    },
    showDelbtn (data) {
      // if (
      //   // 系统管理员
      //   (this.roleCode === sysManager && data.companyType === 4) ||
      //   // 集团管理员
      //   (this.roleCode === groupManager && data.companyType === 1) ||
      //   // 核心企业
      //   (this.roleCode === PLATFORM_MANAGER &&
      //     (data.companyType === 2 || data.companyType === 3))
      //   // 区域 子公司
      //   // (this.roleCode === PLATFORM_MANAGER && data.companyType === 1)
      // ) {
      //   return true
      // }
      // return false
    },
    handleSearch () {
      let value = this.searchValue
      const params = {}
      if (value) {
        params.companyName = value
      }
      orgApi.tenantlist(params).then((res) => {
        if (res.data) {
          this.baseInitData = res.data
          this.data = buildOrgTree(res.data)
          if (!Object.keys(this.currentItem).length) {
            this.currentItem = this.data[0]
          }
        }
      })
      if (!value) {
        this.data = buildOrgTree(this.baseInitData)
        if (!Object.keys(this.currentItem).length) {
          this.currentItem = this.data[0]
        }
        return
      }
      // 字母
      if (/^[A-Za-z]+$/.test(value)) {
        value = value.toLowerCase()
        this.data = buildOrgTree(
          this.baseInitData.filter((it) => it.spell.includes(value))
        )
        if (
          Object.keys(this.currentItem).length > 0 &&
          this.currentItem.spell.includes(value)
        ) {
          return
        }
      } else {
        this.data = buildOrgTree(
          this.baseInitData.filter((it) => it.companyName.includes(value))
        )
        if (
          Object.keys(this.currentItem).length > 0 &&
          this.currentItem.companyName.includes(value)
        ) {
          return
        }
      }
      this.currentItem = (this.data && this.data[0]) || {}
    },

    nodeExpand (data, { id }) {
      if (this.currentDefaultKeys.includes(id)) {
        return
      }
      this.currentDefaultKeys.push(id)
    },

    nodeCollapse (data, { id }) {
      const ids = this.currentDefaultKeys.findIndex((it) => it === id)
      this.currentDefaultKeys.splice(ids, 1)
    },
    // TODO  新增数据逻辑处理
    loadData (isReload, delLoad) {
      orgApi.tenantlist().then((res) => {
        if (res.data) {
          this.baseInitData = res.data
          this.data = buildOrgTree(res.data)
          if (!Object.keys(this.currentItem).length) {
            this.currentItem = this.data[0]
          }
          if (delLoad) {
            this.currentItem = this.data[0]
          }
          if (isReload) {
            this.searchValue = ''
            // 处理新增 删除 编辑后的交互逻辑
            this.$nextTick(() => {
              this.defaultExpandedKeys = this.currentDefaultKeys
            })
          }
        }
      })
    },
    handleNodeClick (data) {
      this.currentItem = data
    },
    handleEditor (data, type, node) {
      this.editorNode = node
      this.editorType = type
      this.$refs.orgEdit.open(data, type)
    },
    filterNode (value, data) {
      if (!value && typeof value !== 'number') {
        return true
      }
      if (data.orgName.indexOf(value) !== -1) {
        return true
      }
    },
    async handleDelItem (data) {
      try {
        await this.$confirm('确认删除该条数据？', '提示')
        orgApi
          .delOrg({
            keyId: data.keyId
          })
          .then(() => {
            this.success('删除成功')
            // this.currentItem = Object.create(null)
            this.loadData(false, true)
          })
          .catch((e) => {
            console.error(e)
          })
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped>
// 样式问题还是有点
.org_tree {
  margin-bottom: 20px;
  /deep/ .el-tree-node__content {
    display: block !important;
  }
  /deep/ .el-tree-node__children {
    overflow: visible !important;
  }
}

.tree_box {
  .input_box {
    display: flex;
    height: 36px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .search_input {
      flex: 1;
    }
    .icon {
      transition: transform 0.3s linear;
      width: 30px;
      color: #96B5FF ;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
    }
    .iconfont.down {
      transform: rotate(180deg);
    }
  }
  .tree_content {
    max-height: calc(100% - 50px);
    box-sizing: border-box;
    overflow: auto;
    /deep/ .el-tree-node__content {
      line-height: 36px;
      height: 36px;
    }
  }
}
.custom-tree-node {
  font-size: 14px;
  .node_label {
    display: inline-block;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 14px;
    &.check {
      color: red;
    }
  }
  .actions {
    margin-left: 16px;
    .el-button {
      font-size: 16px;
      color: #333333;
      padding-left: 0;
      /deep/ i.el-icon-delete {
        color: red;
      }
    }
  }
}
</style>
