import { render, staticRenderFns } from "./exclude-special-input.vue?vue&type=template&id=3a20d40f&scoped=true&"
import script from "./exclude-special-input.vue?vue&type=script&lang=js&"
export * from "./exclude-special-input.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a20d40f",
  null
  
)

export default component.exports