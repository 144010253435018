<template>
  <el-tooltip :disabled="disabled" :content="content" placement="top">
    <span @mouseenter="handleNodeMouseEnter($event)">{{ label }}</span>
  </el-tooltip>
</template>

<script>

const getCell = (event) => {
  var cell = event.target
  while (cell && cell.tagName.toUpperCase() !== 'HTML') {
    if (
      cell.tagName.toUpperCase() === 'SPAN' &&
      cell.classList.contains('node_label')
    ) {
      return cell
    }
    cell = cell.parentNode
  }

  return null
}
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      disabled: true
    }
  },
  methods: {
    handleNodeMouseEnter (e) {
      const cell = getCell(e)
      if (!cell) {
        return
      }
      if (
        cell.offsetWidth &&
        cell.scrollWidth &&
        cell.scrollWidth > cell.offsetWidth
      ) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    }
  }
}
</script>

<style>
</style>
