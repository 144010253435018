<template>
  <el-input v-bind="$attrs" v-on="currentListeners" size="mini">
    <el-button
      v-if="showAppend"
      class="appendBtn"
      slot="append"
      icon="el-icon-search"
      @click="search"
      size="mini"
    ></el-button>
  </el-input>
</template>

<script>
export default {
  name: 'ExcludeSpecialInput',
  props: {
    showAppend: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    currentListeners () {
      const _this = this
      return Object.assign({}, this.$listeners, {
        input: function (val) {
          _this.inputEvent(val)
        }
      })
    }
  },
  methods: {
    search () {
      this.$emit('search')
    },
    inputEvent (val) {
      const reg = /(^$)|(^[\u4E00-\u9FA5a-zA-Z0-9,，]{1,250}$)/
      if (!reg.test(val)) {
        return
      }
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>
</style>
